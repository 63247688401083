import React from "react"
import { Link } from "gatsby"

const SliderProducts = ({ data, ticker }) => {
  return (
    <section className="intro-background-intropagesA-B">
      <div>
        <section className="section-intropagesA-B ">
          <section>   
            {data.title}
            <br />
            {data.textbody}
            <br />
            <br />
            <Link
              className="button"
              href={data.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.textButton}
            </Link>
          </section>
          <section className="">
            {ticker ?    <img
              loading="lazy"
              className="intro-background-intropagesA-B-banner-image intro-background-intropagesA-B-banner-image-ticker"
              src={data.image}
              alt=""
            /> :    <img
            loading="lazy"
            className="intro-background-intropagesA-B-banner-image "
            src={data.image}
            alt=""
          />}
         
          </section>
        </section>
      </div>
    </section>
  )
}

export default SliderProducts
