import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import ImageTestimonies from "./images/imagenTestimonies.webp"
import ImageTestimoniesMobile from "./images/imagenTestimoniesMobile.webp"


const Testimonies = ({ data, location, title, iconsSilder, titleSilder, buttonSilder }) => {
  const [widthWindows, setWidthWindows] = useState()

  useEffect(() => {
    setWidthWindows(window.innerWidth)
  })

  const dotsClassDefault = "slick-dots" // clase por defecto de la libreria
  const dotsClassCustom = "slick-dots-products"

  const activateSlider = {
    customPaging: function (i) {
      return (<button>
        {iconsSilder(i) !== "" ? <img
          src={iconsSilder(i)}
          alt="logo"
          loading="lazy"
          className="container-testimonies-silder-button-icon"
        />
          : ""}
        {titleSilder(i) === "Conoce otros casos de éxito" ?
          <p className="container-testimonies-silder-button-casos">{titleSilder(i)}</p>
          : <p className="container-testimonies-silder-button-text">{titleSilder(i)}</p>}
      </button>
      )
    }
    ,
    slidesToShow: 1,
    infinite: true,
    initialSlide: 0,
    dots: true,
    speed: 500,
    dotsClass: `${dotsClassDefault} ${dotsClassCustom}`,
    afterChange: function (index) {
      const dots = document.querySelector(`.${dotsClassCustom}`)
      dots.scrollLeft = 100 * index
    },
  }

  const activateSliderOne = {
    slidesToShow: 1,
    infinite: true,
    initialSlide: 0,
    dots: widthWindows < 700 ? true : false,
    speed: 500,
    // dotsClass: `${dotsClassDefault} ${dotsClassCustom}`,
    // afterChange: function (index) {
    //   const dots = document.querySelector(`.${dotsClassCustom}`)
    //   dots.scrollLeft = 100 * index
    // },
  }




  return (
    <section className="container-testimonies">
      <p className="container-testimonies-title">
        {title}
      </p>
      {data.length > 1 && !buttonSilder ?
        <div className="container-testimonies-silder">
          <Slider {...activateSlider}>
            {data.map((selectElement, i) => {
              return (
                selectElement.title === 'conoce' ?
                  <section key={i} className="container-testimonies-silder-item" >
                    <section className="container-testimonies-silder-item-image-caso">
                      <div className="container-testimonies-silder-item-image-caso-mobile">
                        <p>Conoce otros casos de éxito</p>
                      </div>

                      <img
                        loading="lazy"
                        className=""
                        src={widthWindows < 700 ? ImageTestimoniesMobile : ImageTestimonies}
                        alt="casos"
                      />
                      <Link
                        to={`/casos-de-exito/${location.search}`}
                        className="container-testimonies-silder-item-link-caso"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Conoce otros casos de éxito
                      </Link>
                    </section>
                  </section>
                  :
                  <section key={i} className="container-testimonies-silder-item" >
                    <section className="container-testimonies-silder-item-description">
                      <div className="container-testimonies-silder-item-type-desktop">
                        <img
                          src={iconsSilder(i)}
                          alt="logo"
                          loading="lazy"
                          className="container-testimonies-silder-item-type-desktop-icon"
                        />
                        <p className="container-testimonies-silder-item-type-desktop-text">
                          {titleSilder(i)}
                        </p>
                        {/* <hr /> */}
                      </div>

                      <img
                        loading="lazy"
                        className="container-testimonies-silder-item-description-logo"
                        src={selectElement?.image}
                        alt="product"
                      />
                      <section className="container-testimonies-silder-item-metrics-mobile">
                        {selectElement?.metrics?.map((ele, i) => {
                          return (
                            <div
                              key={i}
                              className="container-testimonies-silder-item-metrics-mobile-item"
                            >
                              <p
                                className="container-testimonies-silder-item-metrics-mobile-item-porcentaje"
                                style={{ color: ele.color }}
                              >
                                {ele.metric}
                              </p>
                              <p className="container-testimonies-silder-item-metrics-mobile-item-info">
                                {ele.descripcion}
                              </p>
                            </div>
                          )
                        })}
                      </section>
                      <div className="container-testimonies-silder-item-description-title">
                        {selectElement?.title}
                      </div>
                      <p className="container-testimonies-silder-item-description-text">
                        {selectElement?.textbody}
                      </p>
                      <p className="container-testimonies-silder-item-description-name">
                        {selectElement?.name}
                      </p>
                      <p className="container-testimonies-silder-item-description-post">
                        {selectElement?.post}
                      </p>
                      <Link
                        to={`${selectElement?.link}${location.search}`}
                        className="container-testimonies-silder-item-description-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span> Conocer más sobre la historia </span>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M6.27567 19C6.17418 19.0006 6.07356 18.9816 5.9796 18.9443C5.88564 18.907 5.80018 18.852 5.72812 18.7825C5.65584 18.7128 5.59846 18.6299 5.55931 18.5385C5.52016 18.4471 5.5 18.3491 5.5 18.2501C5.5 18.1511 5.52016 18.0531 5.55931 17.9617C5.59846 17.8704 5.65584 17.7874 5.72812 17.7177L12.0288 11.5913C12.4621 11.1695 12.7054 10.5977 12.7054 10.0015C12.7054 9.40538 12.4621 8.8336 12.0288 8.4118L5.72812 2.28535C5.5829 2.14415 5.50132 1.95263 5.50132 1.75294C5.50132 1.55325 5.5829 1.36173 5.72812 1.22053C5.87334 1.07933 6.0703 1 6.27567 1C6.48104 1 6.678 1.07933 6.82322 1.22053L13.1239 7.34698C13.483 7.69526 13.7679 8.10901 13.9623 8.56452C14.1567 9.02003 14.2568 9.50836 14.2568 10.0015C14.2568 10.4947 14.1567 10.983 13.9623 11.4385C13.7679 11.894 13.483 12.3078 13.1239 12.6561L6.82322 18.7825C6.75116 18.852 6.6657 18.907 6.57174 18.9443C6.47778 18.9816 6.37717 19.0006 6.27567 19Z" />
                        </svg>
                      </Link>
                    </section>
                    <section className="container-testimonies-silder-item-metrics-desktop">
                      {selectElement?.metrics.map((ele, i) => {
                        return (
                          <div
                            key={i}
                            className="container-testimonies-silder-item-metrics-desktop-item"
                          >
                            <p
                              className="container-testimonies-silder-item-metrics-desktop-item-porcentaje"
                              style={{ color: ele.color }}
                            >
                              {ele.metric}
                            </p>
                            <p className="container-testimonies-silder-item-metrics-desktop-item-info">
                              {ele.descripcion}
                            </p>
                          </div>
                        )
                      })}

                    </section>
                    <section className="container-testimonies-silder-item-image">
                      <img
                        loading="lazy"
                        src={widthWindows < 700 ? selectElement?.imgMobile : selectElement?.imgDesktop}
                        alt="testimonio"
                      />
                    </section>
                  </section>
              )
            })}
          </Slider>
        </div>
        :
        <div className="container-testimonies-one">
           <Slider {...activateSliderOne}>
          {data.map((selectElement, i) => {
            return (
              <section key={i} className="container-testimonies-one-item" >
                <section className="container-testimonies-one-item-description">
                  <img
                    loading="lazy"
                    className="container-testimonies-one-item-description-logo"
                    src={selectElement?.image}
                    alt="product"
                  />
                  <section className="container-testimonies-one-item-metrics-mobile">
                    {selectElement?.metrics?.map((ele, i) => {
                      return (
                        <div
                          key={i}
                          className="container-testimonies-one-item-metrics-mobile-item"
                        >
                          <p
                            className="container-testimonies-one-item-metrics-mobile-item-porcentaje"
                            style={{ color: ele.color }}
                          >
                            {ele.metric}
                          </p>
                          <p className="container-testimonies-one-item-metrics-mobile-item-info">
                            {ele.descripcion}
                          </p>
                        </div>
                      )
                    })}
                  </section>
                  <div className="container-testimonies-one-item-description-title">
                    {selectElement?.title}
                  </div>
                  <p className="container-testimonies-one-item-description-text">
                    {selectElement?.textbody}
                  </p>
                  <p className="container-testimonies-one-item-description-name">
                    {selectElement?.name}
                  </p>
                  <p className="container-testimonies-one-item-description-post">
                    {selectElement?.post}
                  </p>
                  {selectElement?.link ?
                  <Link
                    to={`${selectElement?.link}${location.search}`}
                    className="container-testimonies-one-item-description-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span> Conocer más sobre la historia </span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M6.27567 19C6.17418 19.0006 6.07356 18.9816 5.9796 18.9443C5.88564 18.907 5.80018 18.852 5.72812 18.7825C5.65584 18.7128 5.59846 18.6299 5.55931 18.5385C5.52016 18.4471 5.5 18.3491 5.5 18.2501C5.5 18.1511 5.52016 18.0531 5.55931 17.9617C5.59846 17.8704 5.65584 17.7874 5.72812 17.7177L12.0288 11.5913C12.4621 11.1695 12.7054 10.5977 12.7054 10.0015C12.7054 9.40538 12.4621 8.8336 12.0288 8.4118L5.72812 2.28535C5.5829 2.14415 5.50132 1.95263 5.50132 1.75294C5.50132 1.55325 5.5829 1.36173 5.72812 1.22053C5.87334 1.07933 6.0703 1 6.27567 1C6.48104 1 6.678 1.07933 6.82322 1.22053L13.1239 7.34698C13.483 7.69526 13.7679 8.10901 13.9623 8.56452C14.1567 9.02003 14.2568 9.50836 14.2568 10.0015C14.2568 10.4947 14.1567 10.983 13.9623 11.4385C13.7679 11.894 13.483 12.3078 13.1239 12.6561L6.82322 18.7825C6.75116 18.852 6.6657 18.907 6.57174 18.9443C6.47778 18.9816 6.37717 19.0006 6.27567 19Z" />
                    </svg>
                  </Link> : null}
                </section>
                <section className="container-testimonies-one-item-metrics-desktop">
                  {selectElement?.metrics.map((ele, i) => {
                    return (
                      <div
                        key={i}
                        className="container-testimonies-one-item-metrics-desktop-item"
                      >
                        <p
                          className="container-testimonies-one-item-metrics-desktop-item-porcentaje"
                          style={{ color: ele.color }}
                        >
                          {ele.metric}
                        </p>
                        <p className="container-testimonies-one-item-metrics-desktop-item-info">
                          {ele.descripcion}
                        </p>
                      </div>
                    )
                  })}

                </section>
                <section className="container-testimonies-one-item-image">
                  <img
                    loading="lazy"
                    src={widthWindows < 700 ? selectElement?.imgMobile : selectElement?.imgDesktop}
                    alt="testimonio"
                  />
                </section>
              </section>
            )
          })}
          </Slider>
        </div>
      }
    </section>
  )
}
export default Testimonies
