import React, { useEffect, useRef } from "react"
import FotoKontigoDesktop from "@components/pageScore/pageContactCenterOmnicanal/image/imgCaso1.png"
import FotoMorneseDesktop from "@components/pageScore/pageContactCenterOmnicanal/image/imgCaso4.png"
import FotoRoyalEnfieldDesktop from "@components/pageScore/pageContactCenterOmnicanal/image/imgCaso2.png"
import FotoAvivaDesktop from "@components/pageScore/pageContactCenterOmnicanal/image/imgCaso3.png"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import TitleUnderline from "./titleUnderline"


const CardsCasos = ({data}) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 14000,
  }
  
  return(
  <section className="container-cardscasos-pagesA-B" ref={fromRef}>
   
    <p className="container-experience-ticker-title">
        <TitleUnderline underline="Potenciamos" />   tus campañas
      </p>
    <section className="container-cards-casosexito container-cardscasos-pagesA-B-desktop">
      <section className="rows-container-testimony">
        <section className="component-card">
          <section className="box-img-testimony">
            <img
              className="foto"
              src={FotoKontigoDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
          <section className="box-text-testimony">
            <h3
              className="card-title-inbox center-text"
              style={{ fontWeight: "bold" }}
            >
              Telemarketing
            </h3>
            <p className="descripcion">
              Maximiza el rendimiento de tus campañas telefónicas y logra tus
              metas comerciales al mejorar la capacidad de contacto con tus
              clientes, integrando tus leads del CRM con nuestra solución
              omnicanal. Lanza llamadas y WhatsApp automáticos para aumentar tu
              conversión.
            </p>
          </section>
        </section>
      </section>

      <div className="flex-container-casosexito reverse">
        {/* Mornese */}
        <section className="rows-container-testimony">
          <div className="card-culqui">
            <section className="descripcion-culqui">
              <h3
                className="card-title-inbox center-text"
                style={{ fontWeight: "bold" }}
              >
                Cobranzas
              </h3>
              <p className="descripcion">
                Mejora la comunicación en tus llamadas de cobros al identificar
                con alta precisión los resultados no contacto, como buzones o
                llamadas sin respuesta, gracias a nuestro marcador inteligente.
                Obtén mejores resultados en tus carteras de mora temprana o
                castigadas, sin importar el nivel de deuda.
              </p>
            </section>
              <img
                className="foto"
                src={FotoMorneseDesktop}
                alt="foto"
                loading="lazy"
              />
          </div>
        </section>
        {/* Dos filas */}
        <div>
          {/* Royal */}
          <section className="rows-container-testimony">
            <section className="card-recupera">
              <section>
                <h3
                  className="card-title-inbox center-text"
                  style={{ fontWeight: "bold" }}
                >
                  Atención al cliente
                </h3>

                <p className="descripcion">
                  Enfocados en la satisfacción de tus clientes, implementa
                  nuestro Contact Center omnicanal y atiéndelos cuando más lo
                  necesiten y a través de su canal de preferencia, brindando una
                  experiencia única a través de nuestra plataforma.
                </p>
              </section>
              <section>
                <img
                  className="foto"
                  src={FotoRoyalEnfieldDesktop}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </section>
          {/* Aaviva */}
          <section
            className="rows-container-testimony"
            style={{ marginBottom: "20px" }}
          >
            <section className="card-netcall">
              <section>
                <img
                  className="foto"
                  src={FotoAvivaDesktop}
                  alt="foto"
                  loading="lazy"
                />
              </section>
              <section>
                <h3
                  className="card-title-inbox center-text"
                  style={{ fontWeight: "bold" }}
                >
                  Televentas
                </h3>

                <p className="descripcion">
                  Mejora tus campañas al comunicarte con clientes potenciales en
                  su momento y canal preferido, aumentando el contacto por
                  teléfono y chat para convertir prospectos en clientes,
                  mientras optimizas tu operación con procesos de backoffice y
                  validación de datos.
                </p>
              </section>
            </section>
          </section>
        </div>
      </div>
    </section>
    <div className="container-cardscasos-pagesA-B-mobile">
    <Slider {...activateSliderTestimonies} ref={sliderRef}>
        {data.map(elemento => {
          return (
            <div key={elemento.title}>
              <div  className="module">
              <div className={`modules ${elemento.module}`}>
                <p className="modules-title center-text">
                  {elemento.title}{" "}
                </p>
                <p className="modules-body center-text">{elemento.info}</p>
                <br />
                <div className="modules-img">
                  <img
                    src={elemento.img}
                    className="modules-icon"
                    style={{width: "100%"}}
                  />
                </div>
              </div>
            </div>
            </div>
            
          )
        })}
      </Slider>
    </div>
  </section>
)
      }
export default CardsCasos